/* global UPLOAD_SPEED_REQUIREMENT,LATENCY_SPEED_REQUIREMENT,UPLOAD_SPEED_CALCUL_DURATION */
/* eslint no-console:0 */
/**
 * Network
 */
const debug = require('debug')('mph:app:network-speed');
const uploadSpeedThreshold = UPLOAD_SPEED_REQUIREMENT;
const latencySpeedThreshold = LATENCY_SPEED_REQUIREMENT;
const uploadSpeedCalculDuration = UPLOAD_SPEED_CALCUL_DURATION;
const timeoutNetworkCheckDurationMs = 10000; // 10s
const ABORT_ERROR_MESSAGE = 'ConnectivityMeasure.Abort'; // Internal
const WEAK_SIGNAL_MESSAGE = 'It seems that your network signal is weak';

let networkCheckDone = false;

exports.UPLOAD_SPEED_THRESHOLD = uploadSpeedThreshold;
exports.LATENCY_SPEED_THRESHOLD = latencySpeedThreshold;

/**
 * @api {Javascript} BioserverNetworkCheck.connectivityMeasure connectivityMeasure
 * @apiVersion ${project.version}
 * @apiName connectivityMeasure
 * @apiGroup NetworkCheck
 *
 * @apiDescription The api allows to check user connectivity requirements for video capture, by calculating latency and upload speed<br>
 *     two verification are done in this order:
 *     <ul>
 *         <li>Latency: if it's good do the next check, otherwise return latency failure without doing up speed check</li>
 *         <li>Upload speed: verify the speed and return the results</li>
 *     </ul>
 *
 *
 * @apiParam {Object} settings
 * @apiParam {String} settings.latencyURL url that will be used for latency check
 * @apiParam {String} settings.uploadURL url that will be used for upload check
 * @apiParam {Function} settings.onNetworkCheckUpdate callback function fired with check results <br>
 *
 * @apiExample {javascript} Example usage
 *  // call it once document loaded
 *  window.onload = () => {
 *      function onNetworkCheckUpdate(networkCheckResults) {
 *          console.log({networkCheckResults});
 *          if (!networkCheckResults.goodConnectivity) {
 *              console.log('BAD user connectivity');
 *              if (networkCheckResults.upload) {
 *                  console.log('Upload requirements not reached');
 *                  console.log('Upload speed threshold is ' + BioserverNetworkCheck.UPLOAD_SPEED_THRESHOLD);
 *              } else if (networkCheckResults.latencyMs) {
 *                  console.log('Latency requirements not reached');
 *                  console.log('Latency speed threshold is ' + BioserverNetworkCheck.LATENCY_SPEED_THRESHOLD); *
 *              } else {
 *                  console.log('Failed to check user connectivity requirements');
 *              }
 *
 *              // STOP user process and display error message
 *
 *          }
 *      }
 *      const urlBasePath = '/demo-server';
 *      BioserverNetworkCheck.connectivityMeasure({
 *          uploadURL:   urlBasePath + '/network-speed',
 *          latencyURL:  urlBasePath + '/network-latency',
 *          onNetworkCheckUpdate: onNetworkCheckUpdate
 *          errorFn: console.log('Failed to check user connectivity requirements');
 *      });
 *  }
 *
 * @apiSuccess {Object}  networkCheckResults                     the results of the connectivity check
 * @apiSuccess {boolean} networkCheckResults.goodConnectivity    false if connectivity requirements are not reached
 * @apiSuccess {number}  networkCheckResults.latencyMs           the value of current latency in milli second
 * @apiSuccess {number}  networkCheckResults.upload              the value of current upload speed (Kbits/s)
 *
 * @apiSuccessExample {javascript} Result of onNetworkCheckUpdate with good connectivity:
 *     // onNetworkCheckUpdate will be called with the result below:
 *     {
 *        "goodConnectivity": true,
 *        "latencyMs": 44,
 *        "upload": 5391,
 *      }
 * @apiErrorExample {javascript} Result onNetworkCheckUpdate with bad connectivity:
 *     // onNetworkCheckUpdate will be called with the result below:
 *     {
 *        "goodConnectivity": false,
 *        "latencyMs": 44,
 *        "upload": 148, // << upload speed not enough
 *      }
 */
exports.connectivityMeasure = function (settings) {
    debug('>> connectivityMeasure');
    networkCheckDone = false;
    // Ensure settings object has expected properties
    if (!settings.uploadURL || !settings.latencyURL || !settings.onNetworkCheckUpdate) {
        const networkConnectivity = initNetworkConnectivity();
        // This timeout should trigger only if errorFn is not defined (legacy)
        const timeoutNetworkCheck = setTimeout(() => {
            sendNetworkCheckResponse(settings, networkConnectivity, timeoutNetworkCheck);
        }, timeoutNetworkCheckDurationMs);
        sendNetworkCheckResponse(settings, networkConnectivity, timeoutNetworkCheck, 400, 'Bad input params');
        return;
    }
    doConnectivityMeasure(settings).catch(err => console.error('Uncaught error in doConnectivityMeasure:', err));
};

function initNetworkConnectivity() {
    return {
        goodConnectivity: false,
        latencyMs: 0,
        upload: 0
    };
}

function sendNetworkCheckResponse(settings, networkConnectivity, timeoutNetworkCheck, errorCode, errorMessage) {
    debug('Send Network check response', networkConnectivity, '\nerror code:', errorCode);
    if (!networkCheckDone) {
        if (errorCode) {
            const errorJson = { code: errorCode, error: errorMessage };
            if (settings.errorFn) {
                settings.errorFn(errorJson);
                networkCheckDone = true;
            } else {
                // Nothing to do for backward compatibility: wait for timeout
                console.error(errorMessage);
            }
        } else {
            if (settings.onNetworkCheckUpdate) {
                settings.onNetworkCheckUpdate(networkConnectivity);
            }
            networkCheckDone = true;
        }
    } else {
        // onNetworkCheckUpdate is already sent
        debug('onNetworkCheckUpdate is already sent');
    }
    if (networkCheckDone) {
        if (timeoutNetworkCheck) {
            clearTimeout(timeoutNetworkCheck);
        }
        debug('<< connectivityMeasure');
    }
}

async function doConnectivityMeasure(settings) {
    const networkConnectivity = initNetworkConnectivity();
    const requestHandler = {};
    const latestUploadData = { // contains the latest data processed by upload handler that will be processed on timeout cases
        size: 0,
        duration: 0,
        speed: 0
    };
    const timeoutHandler = () => {
        debug('Unable to check user connectivity during ' + (timeoutNetworkCheckDurationMs / 1000) + 's');
        // If a request is pending while timeout occurs, abort it
        if (requestHandler.abortRequest) {
            requestHandler.abortRequest();
        }

        // on timeout, retrieve the latest upload progress data available and checks if the threshold is reached or not
        // on some cases, we have 80% of the upload progress but the last 'tick' is never received by server
        if (latestUploadData.speed) {
            debug('Latest upload data content ' + JSON.stringify(latestUploadData));
            networkConnectivity.upload = latestUploadData.speed;
            sessionStorage.setItem('networkUploadSpeed', '' + latestUploadData.speed);
            networkConnectivity.goodConnectivity = (latestUploadData.speed >= uploadSpeedThreshold);
            if (!networkConnectivity.goodConnectivity) {
                networkConnectivity.message = WEAK_SIGNAL_MESSAGE;
            }
        }

        sendNetworkCheckResponse(settings, networkConnectivity, timeoutNetworkCheck);
    };
    const timeoutNetworkCheck = setTimeout(timeoutHandler, timeoutNetworkCheckDurationMs);
    try {
        // Latency check
        networkConnectivity.latencyMs = await computeAvgPingLatency(settings.latencyURL, 3, requestHandler);
        debug('Latency check complete: ' + networkConnectivity.latencyMs + ' ms');
        sessionStorage.setItem('networkLatencySpeed', '' + networkConnectivity.latencyMs);
        if (networkConnectivity.latencyMs > latencySpeedThreshold) {
            networkConnectivity.message = WEAK_SIGNAL_MESSAGE;
            sendNetworkCheckResponse(settings, networkConnectivity, timeoutNetworkCheck);
            return;
        }
        // Upload speed check
        const currentUploadSpeed = await performUploadSpeedRequest(settings.uploadURL, latestUploadData, requestHandler);
        debug('Upload speed check complete: ' + currentUploadSpeed + ' Kb/s');
        networkConnectivity.upload = currentUploadSpeed;
        sessionStorage.setItem('networkUploadSpeed', '' + currentUploadSpeed);
        networkConnectivity.goodConnectivity = (currentUploadSpeed >= uploadSpeedThreshold);
        if (!networkConnectivity.goodConnectivity) {
            networkConnectivity.message = WEAK_SIGNAL_MESSAGE;
        }
        requestHandler.abortRequest = null;
        sendNetworkCheckResponse(settings, networkConnectivity, timeoutNetworkCheck);
    } catch (err) {
        if (err.message === ABORT_ERROR_MESSAGE) {
            debug('ABORT_ERROR_MESSAGE received', err);
            // Response has already been processed, ignore...
            return;
        }
        debug('Got error in doConnectivityMeasure', err);
        sendNetworkCheckResponse(settings, networkConnectivity, timeoutNetworkCheck, err.code || 500, err.message);
    }
}

function performUploadSpeedRequest(url, latestUploadData, requestHandler) {
    const uploadFileSize = 10 * 1024 * 1024; // Value in bytes. Note: greater value may fail in incognito mode
    const file = generateBlobWithRandomBytes(uploadFileSize);
    const req = new XMLHttpRequest();
    // Assign the xhr abort method to this handler so that current request can be aborted on timeout
    requestHandler.abortRequest = function () {
        req.abort();
    };
    return new Promise((resolve, reject) => {
        debug('performUploadSpeedRequest');
        const startTime = performance.now();
        // In seconds
        const getDuration = function () {
            return (performance.now() - startTime) / 1000;
        };
        // In Kb/s
        const getCurrentUploadSpeed = function (duration = getDuration(), size = uploadFileSize) { // size in bytes
            return Math.floor((size * 8 / 1024) / duration);
        };

        req.open('POST', url + `?v=${startTime}`);

        // Indicates if upload.onload() has been called
        let done = false;
        // In normal conditions, we should not need this callback since we have already calculated the speed in upload.onload() or upload.onprogress()
        // But there are cases (Safari...) where the upload callbacks are not called properly, so we perform the calculation here if not done yet
        req.onload = function () {
            if (done) {
                return;
            }
            if (this.status !== 200 && this.status !== 204) {
                reject(Object.assign(new Error('Upload speed check failed due to bad status received'), {
                    code: this.status
                }));
                return;
            }
            debug('Upload file completed after server response');
            resolve(getCurrentUploadSpeed());
        };

        req.upload.onload = function () {
            done = true;
            debug('Upload file completed!');
            resolve(getCurrentUploadSpeed());
        };

        req.upload.onprogress = function (evt) {
            if (!evt.lengthComputable) {
                console.error('Upload speed check failed: Unable to compute progress information since the total size is unknown');
                return;
            }
            const duration = getDuration();
            latestUploadData.size = evt.loaded;
            latestUploadData.duration = duration;
            latestUploadData.speed = getCurrentUploadSpeed(duration, evt.loaded);
            if (duration * 1000 > uploadSpeedCalculDuration) {
                debug('Upload file chunk completed in ' + duration + 's, size: ' + (evt.loaded / 1024) + ' KBytes, speed: ' + latestUploadData.speed + ' Kb/s');
                resolve(getCurrentUploadSpeed(duration, evt.loaded));
                req.abort();
            } else {
                debug('Sent intermediate file chunk at ' + duration + 's' + ', size: ' + (evt.loaded / 1024) + ' KBytes, speed: ' + latestUploadData.speed + ' Kb/s');
            }
        };
        req.onerror = function () {
            reject(new Error('Network error while performing upload speed check'));
        };
        req.onabort = function () {
            reject(new Error(ABORT_ERROR_MESSAGE));
        };
        req.send(file);
    });
}

// the first latency check will be ignored (when having a low connection, we noticed that the first can be longer then others)
async function computeAvgPingLatency(url, count, requestHandler) {
    const totalCount = count;
    await performLatencyRequest(url, requestHandler); // add one fake check to initiate the latency check
    let latencies = 0;
    while (count--) {
        latencies += await performLatencyRequest(url, requestHandler);
    }
    requestHandler.abortRequest = null;
    return Math.floor(latencies / totalCount);
}

function performLatencyRequest(url, requestHandler) {
    const req = new XMLHttpRequest();
    // Assign the xhr abort method to this handler so that current request can be aborted on timeout
    requestHandler.abortRequest = function () {
        req.abort();
    };
    return new Promise((resolve, reject) => {
        debug('performLatencyRequest');
        const fullUrl = url + '?v=' + performance.now();
        req.open('GET', fullUrl);
        req.onload = function () {
            if (this.status !== 200 && this.status !== 204) {
                reject(Object.assign(new Error('Latency check failed due to bad status received'), {
                    code: this.status
                }));
                return;
            }
            // XMLHttpRequest has created a PerformanceEntry with fullUrl as name
            let entry = performance.getEntriesByName(fullUrl)[0];
            if (!entry) {
                const entries = performance.getEntries();
                // Entry not found ? Fallback to last entry (Safari bug...)
                // Note: this may give an incorrect result but since there are several latency calls, we can live with it
                entry = entries[entries.length - 1];
                if (!entry) {
                    reject(new Error('Could not find any performance entry to calculate latency'));
                    return;
                }
            }
            const currentLatency = entry.responseStart - entry.requestStart; // latency (TTFB) = responseStart - requestStart
            resolve(currentLatency >= 0 ? currentLatency : 0);
        };
        req.onerror = function () {
            reject(new Error('Network error while performing latency check'));
        };
        req.onabort = function () {
            reject(new Error(ABORT_ERROR_MESSAGE));
        };
        req.send();
    });
}

function generateBlobWithRandomBytes(size) {
    return new Blob([new ArrayBuffer(size)], { type: 'application/octet-stream' });
}
